// @ts-nocheck
import styled, { keyframes } from "styled-components";
import shipmonk from "./shipmonk.png";
import redbull from "./redbull.svg";
import astratex from "./astratex.png";
import ECB from "./ecb.png";
import parraleum from "./paralleum.jpg";
import schrenker from "./schenker.png";
import cv from "./cv-jakub-sramek.pdf";
import ReferenceLogo from "./components/Logo";
import LogoImg from "./Avatar.png";
import webscope from "./webscope.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faDesktop,
  faMobileScreen,
  faUsersLine,
  faGears,
} from "@fortawesome/free-solid-svg-icons";

const RootContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const AppContainer = styled.div`
  padding: 0 150px;
  @media (max-width: 750px) {
    padding: 0 20px;
  }
`;

const NavContainer = styled.div`
  width: 100%;
  height: 4rem;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #64ffda;
  justify-content: space-between;
  z-index: 20;
  background-color: #0a192f;
  box-shadow: 0px 40px 50px 40px rgba(0, 0, 0, 0.2);
`;

const Logo = styled.img`
  margin-left: 30px;
  border-radius: 24px;
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 750px) {
    display: none;
  }
`;

const Option = styled.a`
  padding: 15px;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  color: #8892b0;
  text-decoration: none;
  &:hover {
    transition: 0.3s;
    color: #64ffda;
  }
`;

const PDFContainer = styled.div``;

const MediaButton = styled.a`
  text-decoration: none;
  border: 1px solid #64ffda;
  background: transparent;
  color: #64ffda;
  cursor: pointer;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 14px;
  margin-right: 10px;
  @media (max-width: 750px) {
    padding: 10px;
    margin-right: 8px;
  }
  &:hover {
    background-color: #64ffda;
    color: #0a192f;
    transition: 0.3s;
  }
`;

const PDFButton = styled.a`
  text-decoration: none;
  border: 1px solid #64ffda;
  background: transparent;
  color: #64ffda;
  cursor: pointer;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 14px 25px;
  margin-right: 30px;
  @media (max-width: 750px) {
    padding: 10px 15px;
    margin-right: 15px;
  }
  &:hover {
    background-color: #64ffda;
    color: #0a192f;
    transition: 0.3s;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  @media (max-width: 750px) {
    padding-top: 3.5rem;
  }
`;

const Title = styled.div`
  text-align: left;
  color: #64ffda;
  font-weight: 500;
  font-size: 1.4rem;
  margin: 0 0 15px 4px;
`;

const Heading = styled.div`
  font-size: 4rem;
  font-weight: 700;
  color: #ccd6f6;
  @media (max-width: 750px) {
    font-size: 2rem;
  }
`;

const SecondaryHeading = styled.span`
  color: #8892b0;
`;

const Description = styled.span`
  max-width: 700px;
  color: #8892b0;
  font-size: 1rem;
  font-weight: 500;
  word-break: break-word;
  margin-top: 30px;
  line-height: 1.4rem;
  @media (max-width: 550px) {
    margin-bottom: 3rem;
  }
`;

const ReferenceBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  overflow: hidden;
  position: relative;
  margin-top: 7rem;
  @media (max-width: 550px) {
    margin-top: 2rem;
  }
`;

const rotate = keyframes`
0% {
    transform: translateX(0);
 }
 100% {
  transform: translateX(calc(-100% + 100vw - 300px));
 }
`;

const ReferenceBar = styled.span`
  display: flex;
  align-items: center;
  animation: 30s linear 0s infinite normal none running ${rotate};
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
`;

const ContactContainer = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 4rem;
  }
`;

const ContactList = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const ContactBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 4rem;
  width: 17rem;
  border: 1px solid #64ffda;
  border-radius: 20px;
  margin: 10px;
`;

const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
`;

const ContactTitle = styled.p`
  margin-left: 15px;
  font-size: 1.2rem;
  color: #64ffda;
  text-decoration: none;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  height: 5rem;
`;

const FooterText = styled.p`
  color: #8892b0;
`;

const ServicesContainer = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardsContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

const animate = keyframes`
from {
  transform: translate(-50%, -50%) rotate(0deg);
}
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const animate2 = keyframes`
from {
  transform: translate(-50%, -50%) rotate(360deg);
}
  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
`;

const animate3 = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
   opacity: .7;
  }
`;

const ServicesLines = styled.div`
  position: absolute;
  inset: 0;
  background: #000;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 120px;
    background: linear-gradient(
      transparent,
      #64ffda,
      #64ffda,
      #64ffda,
      transparent
    );
    animation: ${animate} 4s linear infinite;
    animation-play-state: paused;
  }
  &:after {
    content: "";
    position: absolute;
    inset: 3px;
    background-color: #0a192f;
  }
`;

const ServicesImgBx = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -60px;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  background-color: #000;
  transition: 0.5s;
  z-index: 10;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 100px;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      transparent,
      #64ffda,
      #64ffda,
      #64ffda,
      transparent
    );
    animation: ${animate2} 6s linear infinite;
    animation-play-state: paused;
  }
  &:after {
    content: "";
    position: absolute;
    inset: 3px;
    background-color: #0a192f;
  }
  svg {
    position: absolute;
    z-index: 1;
    font-size: 30px;
    transition: 0.5s;
  }
`;

const ServicesContent = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  display: flex;
`;
const ServicesContentHeading = styled.h3`
  font-size: 30px;
  color: #64ffda;
`;

const ServicesContentDescription = styled.p`
  display: none;
  transition: 0.5s;
  padding: 0 10px 0 20px;
  text-align: center;
  line-height: 24px;
  animation: ${animate3} 1.3s linear;
  animation-play-state: paused;
  opacity: 0.7;
`;

const ServicesCard = styled.div`
  position: relative;
  width: 350px;
  height: 140px;
  background: #fff;
  transition: 0.5s;
  background-color: #0a192f;
  margin: 5rem 0.5rem 0 0.5rem;
  cursor: pointer;
  &:hover {
    height: 340px;
    ${ServicesLines}::before {
      animation-play-state: running !important;
    }
    ${ServicesImgBx} {
      width: 120px;
      height: 120px;
      svg {
        font-size: 50px;
      }
    }
    ${ServicesImgBx}::before {
      height: 120px;
      animation-play-state: running !important;
    }
    ${ServicesContentDescription} {
      animation-play-state: running !important;
      display: flex;
    }
  }
  inset: 0;
`;

function App() {
  return (
    <RootContainer>
      <NavContainer>
        <Logo title="logo" src={LogoImg} width={48} height={48}></Logo>
        <OptionsContainer>
          <Option aria-label="Link to home section" title="Home" href="#home">
            Home
          </Option>
          <Option
            aria-label="Link to services section"
            title="Services"
            href="#services"
          >
            Services
          </Option>
          <Option
            aria-label="Link to contact section"
            title="Contact"
            href="#contact"
          >
            Contact
          </Option>
        </OptionsContainer>
        <PDFContainer>
          <MediaButton
            aria-label="Link to Linkedin"
            href="https://www.linkedin.com/in/jakub-%C5%A1r%C3%A1mek-448b4b1b1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </MediaButton>
          <MediaButton
            aria-label="Link to Github"
            href="https://github.com/JakubSramek1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </MediaButton>
          <PDFButton
            href={cv}
            download="CV-Jakub-Sramek"
            target="_blank"
            rel="noreferrer"
          >
            Download CV
          </PDFButton>
        </PDFContainer>
      </NavContainer>
      <AppContainer>
        <HeaderContainer id="home">
          <Title>Hi, my name is</Title>
          <Heading>
            Jakub Šrámek
            <br />
            <SecondaryHeading>I build things for the Web</SecondaryHeading>
          </Heading>
          <Description>
            I am your Frontend Developer with rich experience working for
            reputed companies. My main focus is on creating quality code and
            delivering projects efficiently. I am no stranger to thinking about
            programming not only from a technical perspective, but also with an
            eye towards solutions that meet business needs and solve business
            problems effectively. My goal is not only to achieve technical
            excellence, but also to deliver measurable value and success for
            your business.
          </Description>
          <ReferenceBarContainer>
            <ReferenceBar>
              <ReferenceLogo href="https://www.paralleum.cz" logo={parraleum} />
              <ReferenceLogo
                href="https://www.redbull.com"
                logo={redbull}
                title="Redbull"
              />
              <ReferenceLogo
                href="https://www.ecb.europa.eu"
                logo={ECB}
                title="Europian Central Bank"
              />
              <ReferenceLogo
                href="https://www.astratex.cz"
                logo={astratex}
                title="Astratex"
              />
              <ReferenceLogo
                href="https://www.webscope.io"
                logo={webscope}
                title="Webscope"
              />
              <ReferenceLogo
                href="https://www.dbschenker.com"
                logo={schrenker}
                title="DBSCHENKER"
              />
              <ReferenceLogo
                href="https://www.shipmonk.com"
                logo={shipmonk}
                title="Shipmonk"
              />
              <ReferenceLogo href="https://www.paralleum.cz" logo={parraleum} />
              <ReferenceLogo
                href="https://www.redbull.com"
                logo={redbull}
                title="Redbull"
              />
              <ReferenceLogo
                href="https://www.ecb.europa.eu"
                logo={ECB}
                title="Europian Central Bank"
              />
              <ReferenceLogo
                href="https://www.astratex.cz"
                logo={astratex}
                title="Astratex"
              />
              <ReferenceLogo
                href="https://www.webscope.io"
                logo={webscope}
                title="Webscope"
              />
              <ReferenceLogo
                href="https://www.dbschenker.com"
                logo={schrenker}
                title="DBSCHENKER"
              />
              <ReferenceLogo
                href="https://www.shipmonk.com"
                logo={shipmonk}
                title="Shipmonk"
              />
            </ReferenceBar>
          </ReferenceBarContainer>
        </HeaderContainer>

        <ServicesContainer id="services">
          <Heading>Services</Heading>
          <CardsContainer>
            <ServicesCard>
              <ServicesLines></ServicesLines>
              <ServicesImgBx>
                <FontAwesomeIcon icon={faDesktop} />
              </ServicesImgBx>
              <ServicesContent>
                <ServicesContentHeading>Web development</ServicesContentHeading>
                <ServicesContentDescription>
                  My main activity is the development of medium and larger web
                  applications or systems that are written in javascript and
                  javascript technologies such as Next.js and React.js. I also
                  focus on developing polymorphic components for these
                  applications.
                </ServicesContentDescription>
              </ServicesContent>
            </ServicesCard>
            <ServicesCard>
              <ServicesLines></ServicesLines>
              <ServicesImgBx>
                <FontAwesomeIcon icon={faMobileScreen} />
              </ServicesImgBx>
              <ServicesContent>
                <ServicesContentHeading>PWA</ServicesContentHeading>
                <ServicesContentDescription>
                  I also have experience with developing progressive web
                  applications for several startups and deploying them on google
                  play and app store.
                </ServicesContentDescription>
              </ServicesContent>
            </ServicesCard>
            <ServicesCard>
              <ServicesLines></ServicesLines>
              <ServicesImgBx>
                <FontAwesomeIcon icon={faUsersLine} />
              </ServicesImgBx>
              <ServicesContent>
                <ServicesContentHeading>Consulting</ServicesContentHeading>
                <ServicesContentDescription>
                  I offer consulting in the field of software development,
                  especially I can help you with the selection of technologies
                  for your project or with solving business problems with
                  software solutions.
                </ServicesContentDescription>
              </ServicesContent>
            </ServicesCard>
            <ServicesCard>
              <ServicesLines></ServicesLines>
              <ServicesImgBx>
                <FontAwesomeIcon icon={faGears} />
              </ServicesImgBx>
              <ServicesContent>
                <ServicesContentHeading>Maintenance</ServicesContentHeading>
                <ServicesContentDescription>
                  I can help you with the maintenance of your existing projects
                  or with the redesign or refactoring of web applications.
                </ServicesContentDescription>
              </ServicesContent>
            </ServicesCard>
          </CardsContainer>
        </ServicesContainer>
        <ContactContainer id="contact">
          <Heading>Contact</Heading>
          <ContactList>
            <ContactBox>
              <ContactIcon>
                <FontAwesomeIcon icon={faPhone} />
              </ContactIcon>
              <ContactTitle>+420 734 858 580</ContactTitle>
            </ContactBox>
            <ContactBox>
              <ContactIcon>
                <FontAwesomeIcon icon={faEnvelope} />
              </ContactIcon>
              <ContactTitle>sramekvm@gmail.com</ContactTitle>
            </ContactBox>
          </ContactList>
        </ContactContainer>
        <FooterContainer>
          <FooterText>Created by Jakub Šrámek in 2023</FooterText>
        </FooterContainer>
      </AppContainer>
    </RootContainer>
  );
}

export default App;

import { FC } from "react";
import styled from "styled-components";

interface Props {
  href: string;
  logo: any
  title: string
}

const Logo = styled.img`
  max-height: 4.5rem;
  margin: 0 1rem;
  :first-child {
    margin: 0 1rem 0 0;
  }
  :last-child {
    margin: 0 0 0 1rem;
  }
  @media (max-width: 750px) {
    max-height: 3rem;
  }
`;

const ReferenceLogo: FC<Props> = ({ href, logo, title }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" title={title}>
      <Logo src={logo} className="App-logo" alt="logo" />
    </a>
  );
};

export default ReferenceLogo

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createGlobalStyle } from "styled-components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GlobalStyle = createGlobalStyle`
  html {
    scrollbar-width: normal;
    scrollbar-color: red;
    scroll-behavior: smooth;
  }
  html::-webkit-scrollbar{
    width: 10px;
    z-index: 0;
  }
  html::-webkit-scrollbar-thumb {
    background-color: #64ffda;
    cursor: pointer;
    border-radius: 4px;
  }
  html::-webkit-scrollbar-track {
    background-color: transparent;
    &:hover {
      background-color: #64ffda70;
    }
  }
  body {
    font-family: 'Raleway', sans-serif !important;
    background-color: #0a192f;
    color: #64ffda;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }`;

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
